<template>
    <div class="content-wrapper">
        <PageHeader screenName="Visualizar tópico" :linkItems="linkItems"/>

        <ErrorModal
            :modalShow="modalError.showModal"
            :typeModal="modalError.typeModal"
            :caseModal="modalError.caseModal"
            @confirmed="redirecionarLista"
        />

        <main class="card p-2 centralizar_responsivo">
            <section class="form">
                <b-form>
                    <b-row class="mb-3">
                        <b-col lg="6" md="6" sm="12">
                            <b-form-group label="Título do tópico" label-class="font-weight-bold">
                                <vue-perfect-scrollbar class="altura_max_input_scrollbar" :settings="settings">
                                    <div>
                                        {{form.topico}}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="6" sm="12">
                            <b-form-group label="Categoria" label-class="font-weight-bold">
                <span v-for="categoria in form.categorias" :key="categoria.id_categoria" class="rounded ajudas">
                  {{ categoria.titulo }}
                </span>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="12" md="6" sm="12">
                            <b-form-group label="Descrição do tópico" label-class="font-weight-bold mb-0">
                                <div v-html="form.conteudo"></div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </section>
            <section class="buttons mt-3">
                <b-button id="save-ajuda" @click.prevent="redirectToEdit()" variant="custom-blue">
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">Editar</span>
                </b-button>
            </section>
        </main>
    </div>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BButton, BFormTextarea } from 'bootstrap-vue';
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import ErrorModal from '@/views/components/custom/modals/ErroIdInexistenteModal.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import { getIdAjuda } from '@core/utils/store/getStore';
import { setIdAjuda } from "@core/utils/store/setStore";

export default {
    title: 'Visualizar tópico de ajuda',

    components: {
        BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BFormTextarea,
        BButton, PageHeader, ErrorModal, VuePerfectScrollbar
    },

    data() {
        return {
            settings: {
                maxScrollbarLength: 70,
                wheelSpeed: 0.2,
                wheelPropagation: false
            },
            linkItems: [
                {
                    name: 'Ajuda e suporte',
                    routeName: 'ajuda-list'
                },
                {
                    name: "Visualizar: "+this.$route.params.topico,
                    active: true
                }
            ],
            modalError: {
                showModal: false,
                typeModal: 'error',
                caseModal: 'idInexistente'
            },
            form: {
                topico: '',
                conteudo: '',
                categorias: null,
                id_categoria: null,
            },
            idAjuda : null
        }
    },

    mounted() {
        if((getIdAjuda() === null || typeof getIdAjuda() == 'undefined') && typeof this.$route.params.idAjuda == "undefined") {

            return this.openModalError();
        }else if(typeof this.$route.params.idAjuda != "undefined" && this.$route.params.idAjuda !== getIdAjuda()) {
            setIdAjuda(this.$route.params.idAjuda);
        }
        this.$http.get(this.$api.ajudaIdAjuda(getIdAjuda())).then(({ data }) => {
            this.preenchimentoFormulario(data);
        });

    },

    methods: {
        preenchimentoFormulario(data){
            this.idAjuda  = getIdAjuda();
            this.form.topico = data.topico;
            this.form.conteudo = data.conteudo;
            this.form.categorias = data.categorias;
            this.linkItems[1].name = data.topico;
        },

        redirectToEdit() {
            this.$router.push(
                {
                    name: 'ajuda-edit',
                    params: {
                        idAjuda: this.$route.params.idAjuda,
                        topico: this.$route.params.topico,
                        conteudo: this.$route.params.conteudo,
                        categoriaSelecionada: this.$route.params.id_categoria
                    }
                }
            );
        },

        redirecionarLista() {
            this.$router.push({ name: 'ajuda-list' });
        },

        openModalError() {
            this.modalError.showModal = true;
        }

    }
}
</script>

<style scoped>
.ajudas{
    background-color: #2772C0;
    color: white;
    padding: 5px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-weight: 400;
}
.altura_max_input_scrollbar{
    max-height:32px;
    word-break: break-all;
    padding-right: 15px;
}
.altura_max_textarea_scrollbar{
    max-height:50vh;
    word-break: break-all;
    padding-right: 15px;
}
.altura_max_ajudas_scrollbar{
    max-height:85px;

}
@media (max-width: 767px) {
    .centralizar_responsivo{
        text-align: center;
        justify-content: center;
    }
}
</style>